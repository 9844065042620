<template>
  <div class="container">
    <div class="card" style="border-radius: 20px;">
      <div
        class="w-100 bg-warning d-flex align-items-center justify-content-center"
        style="border-radius: 20px 20px 0 0;background-image: linear-gradient(to right, #1D2671 , #830051);"
      >
        <h1 class="my-4 text-white">Status</h1>
      </div>
      <div class="px-5 pt-5">
        <a-steps :current="step" size="small">
          <a-step title="Pilih Laboratorium" />
          <a-step title="Unggah Hasil Tes" />
        </a-steps>
      </div>
      <div class="d-flex flex-column align-items-center justify-content-center my-5">
        <div style="width: 50px; height: 50px;" class="my-3" :class="$style['icons-list']">
          <ExclamationCircleTwoTone two-tone-color="#eb2f96" :style="{ fontSize: '50px' }" />
        </div>
        <p v-if="step === 0" class="m-0 text-center mb-4 mb-sm-3">
          Anda belum memasukkan dokumen persetujuan, silahkan isi dokumen.
        </p>
        <p v-else-if="step === 1" class="m-0 text-center mb-4 mb-sm-3">
          Hasil tes anda kosong, apakah anda ingin melakukan tes?
        </p>

        <a-button
          v-if="step === 0"
          type="primary"
          html-type="submit"
          :class="$style['custom-button-yes']"
          :loading="loading"
          @click="handleStepRoute"
        >
          <strong>Mengisi Dokumen</strong>
        </a-button>
        <div v-if="step === 1">
          <a-button
            type="primary"
            html-type="submit"
            :class="$style['custom-button-yes']"
            :loading="loading"
            @click="handleStepRoute"
          >
            <strong>Unggah hasil tes</strong>
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  onMounted,
  onBeforeUnmount,
  ref,
  reactive,
  createVNode,
  toRef,
  toRefs,
  watch,
  computed,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { baseURL } from '@/services/axios'
import { useStore } from 'vuex'
import {
  CheckOutlined,
  ToolOutlined,
  PhoneOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  InfoCircleOutlined,
  HomeOutlined,
  ContactsOutlined,
  ExclamationCircleTwoTone,
} from '@ant-design/icons-vue'
import { Modal, notification, message } from 'ant-design-vue'
import { useForm } from '@ant-design-vue/use'
import axios from 'axios'

export default {
  components: {
    ExclamationCircleTwoTone,
  },
  setup() {
    /* eslint-disable */
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    let checked = ref(false)

    let step = ref(1)
    let data = ref({
      pilihLaboratorium: 'done',
      unggahHasilTes: '',
    })

    const handleStepRoute = () => {
      switch (step.value) {
        case 0:
          router.push(`/program/unggah-hasil-tes`)
          break
        case 1:
          router.push(`/program/unggah-hasil-tes`)
          break
        default:
          break
      }
    }
    onMounted(async () => {
      const testLabTypeId = store.state.testLabTypeId

      if (!testLabTypeId) {
        router.push('/mainProgram')
      }
      if (!data.value.pilihLaboratorium) {
        step.value = 0
      } else if (!data.value.unggahHasilTes) {
        step.value = 1
      }
    })
    onBeforeUnmount(() => {})

    return {
      checked,
      step,
      handleStepRoute,
    }
  },
}
</script>

<style lang="scss" module>
:global(.ant-steps-item-process .ant-steps-item-icon) {
  background: #c2d52f !important;
  border-color: #c2d52f !important;
}
:global(.ant-steps-item-finish .ant-steps-item-icon) {
  // background: #c2d52f !important;
  border-color: #c2d52f !important;
}

:global(.ant-steps-icon > span > svg) {
  fill: #c2d52f !important;
}
:global(.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after) {
  background-color: #c2d52f !important;
}
:global(.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after) {
  background-color: #c2d52f !important;
}
:global(.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title) {
  color: #c2d52f !important;
}
:global(.ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title) {
  color: #94a517 !important;
}
.icons-list :deep(.anticon) {
  margin-right: 6px;
  font-size: 50px;
}

.custom-button-yes {
  // width: 100%;
  height: 50px !important;
  font-weight: 500;
  font-size: 15px !important;
  text-transform: uppercase;
  background: linear-gradient(to right, #eda702, #c76b1e) !important;
  border: 1px solid #eda702 !important;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #eda702 !important;
    background: transparent !important;
  }
}
</style>
